import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'redux/configureStore';
import routes from 'helpers/routes';
import { getCurrentBrand } from 'redux/ducks/brand';
import { RootState } from 'redux/ducks';
import {
  FlashMessages,
  EULAModal,
  BrandStyleSheet,
  BatchDownloadSpinner,
  AthleteOptInModal,
} from './components';
import { ResetPassword } from 'components/ResetPassword';
import { FacebookPageLogin } from 'components/FacebookPageLogin/FacebookPageLogin';
import { fetchCurrentPublisher } from 'redux/ducks/publisher';
import {
  LoadingPage,
  PageTitle,
  Loader,
  ErrorPage,
  GlobalNav,
} from 'components/shared';
import { useAppDispatch } from 'redux/store';
import AccountConversion from 'components/AccountConversion';
import { ErrorBoundary } from '@sentry/react';

const ExamplePostUrlEmbed = lazy(() =>
  import('components/ExamplePostUrlEmbed')
);
const Inbox = lazy(() => import('components/Inbox'));
const Athlete = lazy(() => import('components/Athlete'));
const Onboarding = lazy(() => import('components/Onboarding'));
const PublisherRequest = lazy(() => import('components/PublisherRequest'));
const Invitation = lazy(() => import('components/Invitation'));
const Media = lazy(() => import('components/Media'));
const Settings = lazy(() => import('components/Settings'));
const SignIn = lazy(() => import('components/SignIn'));
const FeedGuard = lazy(() => import('components/FeedGuard/FeedGuard'));
const PublisherCode = lazy(() => import('components/PublisherCode'));
const PublisherSelect = lazy(() => import('components/PublisherSelectScreen'));
const MagicSignIn = lazy(() => import('components/MagicSignIn'));

export default function Layout() {
  const [isLoading, setIsLoading] = useState(true);
  const { brand } = useSelector((state: RootState) => ({
    brand: getCurrentBrand(state),
  }));
  const dispatch = useAppDispatch();

  const title = 'Socialie';

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const fetchPublisher = async () => {
      setIsLoading(true);
      try {
        await dispatch(fetchCurrentPublisher());
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    };

    fetchPublisher();
  }, [dispatch]);

  if (isLoading) return <LoadingPage />;

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <PageTitle />
      {brand && brand.loaded && <BrandStyleSheet brand={brand} />}

      <ConnectedRouter history={history}>
        <RouteTree />
      </ConnectedRouter>
    </ErrorBoundary>
  );
}

function RouteTree() {
  const location = useLocation();
  const routesWithNavbar = [
    routes.inbox.root,
    routes.media.root,
    routes.settings.root,
    routes.athlete.root,
  ];

  const isDirectUploadsScreen =
    location.pathname === routes.media.directUploads;

  const isNavbarVisible =
    routesWithNavbar.some(
      (route) =>
        location.pathname === route || location.pathname.startsWith(`${route}/`)
    ) && !isDirectUploadsScreen;

  return (
    <>
      {isNavbarVisible && <GlobalNav />}

      <Suspense fallback={<Loader isLoading initialLoad />}>
        <Switch>
          <Route
            path={routes.fromWebRedirect}
            render={({ location }) => {
              const newPath = location.pathname.replace('/from-web', '');
              return <Redirect to={`${newPath}${location.search}`} />;
            }}
          />
          <Route path={routes.media.root} component={Media} />
          <Route path={routes.inbox.root} component={Inbox} />
          <Route
            path={routes.auth.facebookPageLogin}
            component={FacebookPageLogin}
          />

          <Route path={routes.settings.root} component={Settings} />
          <Route path={routes.auth.login.root} component={SignIn} />
          <Route
            path={routes.auth.resetPassword}
            exact
            component={ResetPassword}
          />
          <Route path={routes.auth.magicSignIn} exact component={MagicSignIn} />
          <Route path={routes.onboarding.all} component={Onboarding} />
          <Route path={routes.onboarding.getStarted} component={Onboarding} />
          <Route
            path={routes.publisherRequests.feedGuard}
            exact
            component={FeedGuard}
          />
          <Route path={routes.athlete.root} component={Athlete} />
          <Route path={routes.publisherSelect} component={PublisherSelect} />
          <Route path={routes.invitation.root} component={Invitation} />

          <Route path="/inbox">
            <Redirect to={routes.inbox.root} />
          </Route>

          <Route path={routes.publisherCode} exact component={PublisherCode} />
          <Route
            path={routes.publisherRequests.exampleEmbed}
            exact
            component={ExamplePostUrlEmbed}
          />
          <Route
            path={routes.publisherRequests.show}
            exact
            component={PublisherRequest}
          />
          <Route
            path={routes.publisherRequests.upgradeInstagram}
            exact
            component={PublisherRequest}
          />
          <Route
            path={routes.contentRequests.index}
            exact
            component={PublisherRequest}
          />
          <Route
            path={routes.publisherRequests.upgradeInstagram}
            exact
            component={PublisherRequest}
          />

          <Route>
            <Redirect to={routes.inbox.root} />
          </Route>
        </Switch>
      </Suspense>

      <FlashMessages />

      <EULAModal />
      {/* <ReconnectAccountNagModal platformId="instagram" /> */}
      <AccountConversion />
      <BatchDownloadSpinner />
      <AthleteOptInModal />
    </>
  );
}

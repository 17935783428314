import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'types/User';
import camelize from 'camelize';
import * as api from 'api/user';
import UpdateUserParams from 'types/UpdateUserParams';
import { fetchedPublisher, clearedPublisher } from './publisher';
import UserApiPayload from 'types/UserApiPayload';
import { fetchedIdentities } from './identities';
import { fetchedNotificationPreferences } from './notificationPreferences';
import { fetchedSuggester } from './suggester';
import { fetchedBrand } from './brand';
import { fetchedLegacyPublisher } from './legacyPublisher';
import { flashMessage } from 'redux-flash';
import { AppDispatch } from 'redux/store';
import { RootState } from '.';
import { identifySentryUser } from 'api/publisher';
import LoggedInPublisher from 'types/LoggedInPublisher';
import MfaValues from 'types/MfaValues';

export interface State {
  current: User | null;
  mfaValues: MfaValues;
  isFetching: boolean;
  fetchSucceeded: boolean;
  fetchFailed: boolean;
}

const initialState: State = {
  current: null,
  mfaValues: {
    mfaPhone: '',
    mfaProtocol: '',
    tempToken: '',
  },
  isFetching: false,
  fetchSucceeded: false,
  fetchFailed: false,
};

const { reducer, actions } = createSlice({
  initialState,
  name: 'publisher/user',
  reducers: {
    fetchUserStart: (state) => {
      state.isFetching = true;
    },
    fetchUserSuccess: (state, action: PayloadAction<User | undefined>) => {
      state.current = action.payload || null;
      state.mfaValues = initialState.mfaValues;
      state.isFetching = false;
      state.fetchSucceeded = true;
    },
    fetchUserMfaRequired: (state, action: PayloadAction<MfaValues>) => {
      state.current = null;
      state.isFetching = false;
      state.mfaValues = action.payload;
      state.fetchSucceeded = true;
    },
    fetchUserFailure: (state) => {
      state.isFetching = false;
      state.fetchFailed = true;
    },
    updatedUser: (state, action: PayloadAction<Partial<UpdateUserParams>>) => {
      Object.assign(state.current, action.payload);
    },
    acceptedEula: (state) => {
      if (state.current) state.current.acceptedLatestEula = true;
    },
  },
});

export default reducer;

export const { updatedUser, fetchUserSuccess, fetchUserMfaRequired } = actions;

export const logInUser = (data: UserApiPayload) => (dispatch: AppDispatch) => {
  api.identifyFullStoryUser(data.user, data.publisher, data.suggester);
  dispatch(fetchUserSuccess(data.user));
  dispatch(fetchedIdentities(data.identities || []));
  if (data.notificationPreferences) {
    dispatch(fetchedNotificationPreferences(data.notificationPreferences));
  }

  if (data.suggester) {
    dispatch(fetchedSuggester(data.suggester));
    dispatch(fetchedBrand(data.brand, data.suggester.codeName));
  }

  if (data.legacyPublisher) {
    dispatch(fetchedLegacyPublisher(data.legacyPublisher));
  }
  if (data.publisher) {
    dispatch(fetchedPublisher(data.publisher));
    if (!data.publisher.loginRequired) {
      identifySentryUser(
        data.user,
        data.publisher as LoggedInPublisher,
        data.suggester
      );
    }
  } else {
    dispatch(clearedPublisher());
  }
};

export const fetchUser = () => async (dispatch: AppDispatch) => {
  dispatch(actions.fetchUserStart());
  try {
    const json = await api.fetchCurrentUser();
    const user = camelize(json.user);
    dispatch(actions.fetchUserSuccess(user));
  } catch (e) {
    console.error(e);
    dispatch(actions.fetchUserFailure());
  }
};

export const acceptEula = () => async (dispatch: AppDispatch) => {
  try {
    await api.acceptEula();
    dispatch(actions.acceptedEula());
  } catch (e) {
    dispatch(flashMessage('Global__UnexpectedError', { isError: true }));
  }
};

// Selectors
export const getCurrentUser = (state: RootState) => state.user.current;
